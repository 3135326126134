
import Vue from 'vue'
import axios from 'axios'

let Url='http://111.90.143.10:39010/api/v1'

axios.defaults.baseURL=Url
axios.interceptors.request.use(config => {  
 // 确保 headers 是一个对象  
  if (!config.headers) {  
    config.headers = {};  
  }  
  
  // 从sessionStorage中获取token  
  const token = window.sessionStorage.getItem("token");  
  
  //如果token存在，则添加到Authorization头中  
  if (token) {  
    config.headers.Authorization = `Bearer ${token}`;  
  }  
  
  return config;  
});
Vue.prototype.$http=axios

export {Url}
