<template>
    <div>

        <a-card :bordered="false">
            <h3>虚拟卡余额和消耗计算</h3>
            <a-row :gutter="20" style="margin-top: 10px;">
                <a-col :span="4">
                    <a-cascader allowClear :options="accountOptions" placeholder="请输入FB账号查找" @change="() => { this.paginationOption.defaultCurrent = 1; getVirtualCard(); }"
                        v-model="queryParams.account" />
                </a-col>
                <a-col :span="4">
                    <a-cascader allowClear :options="cardNumber_options" placeholder="请输入卡号查找" @change="() => { this.paginationOption.defaultCurrent = 1; getVirtualCard(); }"
                        v-model="queryParams.id" />
                </a-col>
                <a-col :span="5">
                    <a-range-picker @change="() => { this.paginationOption.defaultCurrent = 1; getVirtualCard(); }" v-model="time_range" />
                </a-col>
                <a-col :span="2">
                    <a-button type="primary" @click="() => { this.paginationOption.defaultCurrent = 1; getVirtualCard(); }">查询</a-button>
                </a-col>
                <a-col :span="2">
                    <a-button type="primary" @click="getClear">置空</a-button>
                </a-col>
            </a-row>
            <a-table :rowKey="(record, index) => { return index }" :columns="columns" :pagination='paginationOption'
                :dataSource="virtualCardList" bordered>
            </a-table>
        </a-card>

    </div>
</template>

<script>
//import { CloudUploadOutlined,InboxOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
const columns = [
    {
        title: '卡号',
        dataIndex: 'card_number',
        width: '10%',
        key: 'card_number',
        align: 'center',
    },
    {
        title: '余额',
        dataIndex: 'Balance',
        width: '15%',
        key: 'Balance',
        align: 'center',
    },
    {
        title: '总消耗',
        dataIndex: 'Deplete',
        width: '15%',
        key: 'Deplete',
        align: 'center',

    }
]
export default {
    data() {
        return {
            labelCol: { span: 5 },
            wrapperCol: { span: 16 },
            columns,
            querydata: {
                card_number: '',
                year: '',
                month: ''
            },
            paginationOption: {
                pageSizeOptions: ['10', '20', '50'],
                defaultCurrent: 1,
                defaultPageSize: 10,
                showSizeChanger: true,
                showTotal: (total) => `共${total}条`,
                onChange: (current, size) => {
                    this.paginationOption.defaultCurrent = current
                    this.paginationOption.defaultPageSize = size
                    this.getVirtualCard()

                },
                onShowSizeChange: (current, size) => {
                    this.paginationOption.defaultCurrent = 1
                    this.paginationOption.defaultPageSize = size
                    this.getVirtualCard()
                },
                total: 0
            },
            virtualCardNumber: [],
            virtualCardList: [],
            month_consumption: '',
            queryParams: {
                pagesize: 0,
                pagenum: 0,
                account:'',
                id:'',
                start_time:'',
                end_time:''
                // title: '',
            },
            time_range:[],
            cardNumber_options: [],
            accountOptions: [],
            totalBalance:'',
            totalDeplete:''
        }
    },
    created() {
        this.getVirtualCard()
        this.getAccountOptions()
        this.getCardNumberOptions()
    },
    methods: {
        async getVirtualCard() {
            this.getCardNumberOptions()
            this.queryParams.pagesize = this.paginationOption.defaultPageSize
            this.queryParams.pagenum = this.paginationOption.defaultCurrent
            this.querydata.start_time = (this.time_range[0])?.startOf('day').unix()
            this.querydata.end_time =(this.time_range[1])?.endOf('day').unix()
            const id = this.queryParams.id === 0 ? '' : this.queryParams.id
            const { data: result } = await this.$http.get(`showVccBalanceAndDeplete?pagesize=${this.queryParams.pagesize}&pagenum=${this.queryParams.pagenum}&account=${(this.queryParams.account|| [])}&id=${id}&start_time=${this.querydata.start_time}&end_time=${this.querydata.end_time}`);
            if (result.code != 200) return this.$message.error(result.message)
            console.log(result, '=====>虚拟卡')
            this.virtualCardList = Object.entries(result.data.CurrentPage).map(([card_number, details]) => ({
                card_number,
                ...details
            }));
            this.paginationOption.total = result.total
        },
        async getAccountOptions() {
            const { data: result } = await this.$http.get('showFBID?account=')
            if (result.code != 200) return this.$message.error(result.message)
            this.accountOptions = result.data1.map(number => ({
                value: number,
                label: number
            }))
            this.getCardNumberOptions()
        },
        getClear() {
            this.queryParams = {
                pagesize: 0,
                pagenum: 0,
                id:'',
                start_time:"",
                end_time:""
                
            }
            this.time_range=[]
            this.getVirtualCard()
        },
        async getCardNumberOptions() {
            const { data: result } = await this.$http.get(`showFBID?account=${this.queryParams.account}`)
            if (result.code != 200) return this.$message.error(result.message)
            this.cardNumber_options = result.data2.map(number => ({
                value: number,
                label: number
            }))
            console.log(this.cardNumber_options, '======>123')
        }
    }
}
</script>

<style scoped>
.actionSlot {
    display: flex;
    justify-content: center;
}
</style>
