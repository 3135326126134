<template>
    <div>

        <a-card :bordered="false">
            <h3>FaceBook文件列表</h3>
            <a-row :gutter="20">
                <a-col :span="4">
                    <a-cascader allowClear :options="accountOptions" placeholder="请输入FB账号查找" 
                        @change="() => { this.paginationOption.defaultCurrent = 1; getArticleList(); }"
                        v-model="querydata.account" />
                </a-col>
                <a-col :span="4">
                    <!-- <a-input-search placeholder="请输入支付类型查找" enter-button v-model="querydata.transaction_type" la
                        @search="getArticleList"></a-input-search> -->
                    <a-cascader allowClear :options="cardNumber_options" placeholder="请输入卡号查找"
                         @change="() => { this.paginationOption.defaultCurrent = 1; getArticleList(); }"
                        v-model="querydata.payment_method" />
                </a-col>
                <a-col :span="5">
                    <a-range-picker  @change="() => { this.paginationOption.defaultCurrent = 1; getArticleList(); }"  v-model="time_range" />
                </a-col>
                <a-col :span="2">
                    <a-button type="primary"  @click="() => { this.paginationOption.defaultCurrent = 1; getArticleList(); }">查询</a-button>
                </a-col>
                <a-col :span="2">
                    <a-button type="primary" @click="getClear">置空</a-button>
                </a-col>
            </a-row>
            <!-- 触发上传弹窗的按钮 -->
            <a-button type="primary" @click="showDialog" style="margin-top:10px ;">FaceBook文件导入</a-button>
            <a-button type="primary" @click="queryTotal"  style="margin-top:10px ;margin-left:20px;">查询卡的总账</a-button>
            <span v-if="totalAccount !== null"> {{ totalAccount }}美元</span>
            <a-modal v-model:visible="dialogVisible" title="批量导入" @ok="handleOk">
                <p>为确保导入数据格式正确，请按照格式规范录入数据</p>
                <a-upload-dragger v-model:fileList="fileList" name="f1" :multiple="true"
                    action="http://111.90.143.10:39010/api/v1/upload2" @change="handleChangeFile">
                    <p class="ant-upload-drag-icon">
                        <a-icon type="cloud-download" />
                    </p>
                    <p class="ant-upload-text">点击或将文件拖拽到这里上传</p>
                    <p class="ant-upload-hint">
                        仅支持.csv文件
                    </p>
                </a-upload-dragger>
                <div class="upload-tips">
                    <p>1.仅支持.csv文件</p>
                    <p>2.导入数量不得超过5000条</p>
                    <p>3.文件大小不得超过3M</p>
                </div>
            </a-modal>
            <a-table rowKey="transaction_id" :columns="columns" :pagination="paginationOption"
                :dataSource="Articlelist" bordered>
                <span slot="customTitle"><div style="display: flex;">时间 <span class="icon-type"><a-icon type="caret-up" @click="getSortArticleList(0)" /><a-icon type="caret-down" @click="getSortArticleList(1)"/></span></div></span>
                <!-- 自定义渲染颜色列 -->
                <template slot="action" slot-scope="text">
                    <a-checkbox 
                        @change="(e) => judgeIsBox(text.transaction_id,text.note,e.target.checked)" v-model="text.is_ticked" />
                </template>
                <template slot="text" slot-scope="text">
                    <a-textarea @change="(e) => judgeIsBox(text.transaction_id,text.note,text.is_ticked)" v-model="text.note" auto-size />
                </template>
            </a-table>
        </a-card>

    </div>
</template>

<script>
//import { CloudUploadOutlined,InboxOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
const columns = [
    {
        title: 'FB账户',
        dataIndex: 'account',
        width: '13%',
        key: 'account',
        align: 'center',
    },
    {
        slots: { title: 'customTitle' },
        dataIndex: 'date',
        width: '12%',
        key: 'date',
        align: 'center',
    },
    {
        title: '交易号',
        dataIndex: 'transaction_id',
        width: '15%',
        key: 'transaction_id',
        align: 'center',

    },

    {
        title: '交易所用虚拟卡',
        dataIndex: 'payment_method',
        width: '10%',
        key: 'payment_method',
        align: 'center',
    },
    {
        title: 'order-amount',
        dataIndex: 'amount',
        width: '10%',
        key: 'amount',
        //scopedSlots: { customRender: 'img' },
        align: 'center',
    },
    {
        title: 'order-amount',
        dataIndex: 'currency',
        width: '10%',
        key: 'currency',
        //scopedSlots: { customRender: 'img' },
        align: 'center',
    },
    {
        title: '标记',
       // dataIndex: 'is_ticked',
        width: '10%',
        key: 'action',
        scopedSlots: { customRender: 'action' },
        // 不需要 scopedSlots，因为我们在模板中直接定义了它  
        align: 'center',
    },
    {
        title: '备注',
       // dataIndex: 'is_ticked',
        width: '10%',
        key: 'text',
        scopedSlots: { customRender: 'text' },
        // 不需要 scopedSlots，因为我们在模板中直接定义了它  
        align: 'center',
    }

]
export default {
    data() {
        return {
            labelCol: { span: 5 },
            wrapperCol: { span: 16 },
            paginationOption: {
                pageSizeOptions: ['10', '20', '50'],
                defaultCurrent: 1,
                defaultPageSize: 10,
                showSizeChanger: true,
                showTotal: (total) => `共${total}条`,
                onChange: (current, size) => {
                    this.paginationOption.defaultCurrent = current
                    this.paginationOption.defaultPageSize = size
                    this.getArticleList()

                },
                onShowSizeChange: (current, size) => {
                    this.paginationOption.defaultCurrent = 1
                    this.paginationOption.defaultPageSize = size
                    this.getArticleList()
                },
                total: 0
            },
            Articlelist: [],
            Catelist: [],
            columns,
            querydata: {
                pagesize: 0,
                pagenum: 0,
                account: '',
                payment_method: '',
                start_time: '',
                end_time: ''
            },
            updateParams: {
                transaction_id: '',
                is_ticked: '',
                note: ''
            },
            time_range: [],
            dialogVisible: false, // 控制弹窗的显示  
            fileList: [], // 已上传的文件列表 
            vccBalence: 0,
            vccVisible: false,
            updateFBList: {
                transaction_id: "",
                is_ticked: false,
                note: ""
            },
            accountOptions: [],
            cardNumber_options: [],
            accountNumber: "",
            totalAccount:null
        }
    },
    created() {
        this.getArticleList()
        this.getAccountOptions()
        this.getCardNumberOptions()
    },
    methods: {
        // 显示上传文件的弹窗  
        showDialog() {
            this.dialogVisible = true;
        },
        handleChangeFile(info) {
            const status = info.file.status;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} 文件上传成功！`);
              
            } else if (status === 'error') {
                const responses = info.file.response.match(/({.*?})/g); // 使用正则表达式提取 JSON 
                // 解析第一个 JSON 对象
                const info1 = JSON.parse(responses[0]); // 解析第一个对象
                console.log(info1, 'info1');
                message.error(`${info1.msg} 文件上传失败！`);
            }
        },
        handleOk(e) {
            console.log(e);
            this.dialogVisible = false;
            this.getArticleList()
        },
        async showVccBalance(id) {
            const result = await this.$http.post('showVccBalance', { card_number: id });
            console.log(result, '=====111')
            if (result.status != 200) return this.$message.error(result.message)
            this.vccBalence = data.vccBalence
            this.vccVisible = true
        },
        gotoEditArt(id) {
            alert(id)
            this.$router.push(`/addart/` + id)
        },
        //文章列表
        async getArticleList() {
            this.getCardNumberOptions()
            this.querydata.pagesize = this.paginationOption.defaultPageSize
            this.querydata.pagenum = this.paginationOption.defaultCurrent
            this.querydata.start_time = (this.time_range[0])?.startOf('day').unix()
            this.querydata.end_time = (this.time_range[1])?.endOf('day').unix()
            const url = `showfb_record?pagesize=${this.querydata.pagesize}&pagenum=${this.querydata.pagenum}&account=${(this.querydata.account|| [])}&payment_method=${(this.querydata.payment_method || [])}&start_time=${this.querydata.start_time}&end_time=${this.querydata.end_time}&set=0`;
            const { data: result } = await this.$http.get(url);
            console.log(result, '====88')
            if (result.code != 200) return this.$message.error(result.message)
            this.Articlelist = result.data
            console.log(this.Articlelist, '=====>xihuan')
            this.paginationOption.total = result.tatal
        },
        async getSortArticleList(id) {
            this.getCardNumberOptions()
            this.querydata.pagesize = this.paginationOption.defaultPageSize
            this.querydata.pagenum = this.paginationOption.defaultCurrent
            this.querydata.start_time = (this.time_range[0])?.startOf('day').unix()
            this.querydata.end_time = (this.time_range[1])?.endOf('day').unix()
            const url = `showfb_record?pagesize=${this.querydata.pagesize}&pagenum=${this.querydata.pagenum}&account=${this.querydata.account}&payment_method=${this.querydata.payment_method}&start_time=${this.querydata.start_time}&end_time=${this.querydata.end_time}&set=${id}`;
            const { data: result } = await this.$http.get(url);
            console.log(result, '====88')
            if (result.code != 200) return this.$message.error(result.message)
            this.Articlelist = result.data
            console.log(this.Articlelist, '=====>xihuan')
            this.paginationOption.total = result.tatal
        },
        async getAccountOptions() {
            const { data: result } = await this.$http.get('showFBID?account=')
            if (result.code != 200) return this.$message.error(result.message)
            this.accountOptions = result.data1.map(number => ({
                value: number,
                label: number
            }))
            this.getCardNumberOptions()
        },
        async getCardNumberOptions() {
            const { data: result } = await this.$http.get(`showFBID?account=${this.querydata.account}`)
            if (result.code != 200) return this.$message.error(result.message)
            this.cardNumber_options = result.data2.map(number => ({
                value: number,
                label: number
            }))
            console.log(this.cardNumber_options, '======>123')
        },
        getClear() {
            this.querydata = {
                pagesize: 0,
                pagenum: 0,
                card_number: '',
                transaction_type: '',
                start_time: '',
                end_time: ''
            }
            this.time_range = []
            this.getArticleList()
        },
        async findArticle() {
            this.querydata.pagesize = this.paginationOption.defaultPageSize
            this.querydata.pagenum = 1
            const { data: result } = await this.$http.post('articles', this.querydata)
            if (result.status != 200) return this.$message.error(result.message)
            this.Articlelist = result.data
            this.paginationOption.total = result.total
        },
        //删除文章
        deleteArticle(id) {
            this.$confirm({
                title: "确定要删除吗？",
                content: "一旦删除，无法恢复",
                onOk: async () => {
                    const { data: result } = await this.$http.delete(`article/${id}`)
                    if (result.status != 200) return this.$message.error(result.message)
                    this.$message.success('删除成功')
                    this.getArticleList()
                },
                onCancel: () => {
                    this.$message.info("已取消删除")
                }
            })
        },
        //获取分类列表
        async getCateList() {
            const { data: result } = await this.$http.get('category')
            if (result.status != 200) return this.$message.error(result.message)
            this.Catelist = result.data
            this.paginationOption.total = result.total
        },
        //查询分类下的文章
        CateChange(value) {
            this.getCateArticle(value)
        },
        async getCateArticle(id) {
            const { data: result } = await this.$http.get(`article/cate/${id}`, {
                params: {
                    pagesize: this.paginationOption.defaultPageSize,
                    pagenum: this.paginationOption.defaultCurrent
                }
            })
            if (result.status != 200) return this.$message.error(result.message)
            this.Articlelist = result.data
            this.paginationOption.total = result.total
        },
        // 显示上传文件的弹窗  
        showDialog() {
            this.dialogVisible = true;
        },
        async judgeIsBox(id, note,is_ticked) {
            console.log(id, is_ticked,note, '=========>12345')
            this.updateFBList.transaction_id = id
            this.updateFBList.is_ticked = is_ticked
            this.updateFBList.note = note
            const { data: result } = await this.$http.post('updateFBList', this.updateFBList)
            if (result.code != 200) return this.$message.error(result.message)
            this.getArticleList()
        },
        async queryTotal() {
            this.querydata.start_time = (this.time_range[0])?.startOf('day').unix()
            this.querydata.end_time = (this.time_range[1])?.endOf('day').unix()
            if(this.querydata.payment_method !== '') {
                const { data: result } = await this.$http.get(`showFBDataByaccount?account=${this.querydata.account}&payment_method=${this.querydata.payment_method}&start_time=${this.querydata.start_time}&end_time=${this.querydata.end_time}`);
                if (result.status != 200) return this.$message.error(result.message)
                this.totalAccount = result.data
            }else {
                message.error('未填写卡号')
            }
            
        },
    },
}
</script>

<style scoped>
.actionSlot {
    display: flex;
    justify-content: center;
}
.icon-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left:5px
}
::v-deep .anticon {
    margin-bottom: -5px;
}
::v-deep .anticon:hover {
    cursor: pointer;
    color:#40a9ff
}
</style>
