<template>
  <a-layout id="components-layout-demo-custom-trigger">
    <a-layout-sider v-model="collapsed" :trigger="null" collapsible width="250">
      <div class="title">
        FB虚拟卡账单管理系统
      </div>
      <a-menu theme="dark" mode="inline" @click="goToPage">
        <a-sub-menu>
          <span slot="title"><a-icon type="file"></a-icon><span>账单管理</span></span>
          <a-menu-item key="addart"><a-icon type="form"></a-icon><span>虚拟卡账单</span></a-menu-item>
          <a-menu-item key="artlist"><a-icon type="snippets"></a-icon><span>facebook账单</span></a-menu-item>
          <a-menu-item key="catelist"><a-icon type="book"></a-icon><span>虚拟卡余额和消耗计算</span></a-menu-item>
          <a-menu-item key="totalBalance"><a-icon type="book"></a-icon><span>FB账号总余额和总消耗</span></a-menu-item>
          <!-- <a-menu-item key="queryCard"><a-icon type="snippets"></a-icon><span>FB虚拟卡扣款</span></a-menu-item> -->
        </a-sub-menu>
        <a-menu-item key="userlist" v-if="userRole == 1">
          <a-icon type="upload" />
          <span>用户列表</span>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0">
        <a-icon class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          @click="() => (collapsed = !collapsed)" />
        <a-button type="danger" @click="loginOut">退出</a-button>
      </a-layout-header>
      <a-layout-content :style="{ margin: '24px 16px', background: '#fff', minHeight: '280px' }">
        <router-view :key="$router.path"></router-view>
      </a-layout-content>
      <a-layout-footer style="background: #fff; padding: 0">
        <Footer></Footer>
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>

<script>
import Nav from "../components/admin/Nav.vue"
import Footer from "../components/admin/Footer.vue"
import { mapState } from 'vuex';
export default {
  data() {
    return {
      collapsed: false,
    };
  },
  created() {
    const role = localStorage.getItem('userRole');
    if (role) {
        this.$store.commit('setUserRole', role);
    }
},
  methods: {
    loginOut() {
      window.sessionStorage.clear('token')
      this.$router.push('/login')
      localStorage.removeItem('userRole');
      commit('setUserRole', null);
    },
    goToPage(item) {
      this.$router.push("/" + item.key).catch(err => err)
    }
  },
  computed: {
    ...mapState(['userRole']),
  },
  components: {
    Nav,
    Footer
  }
};
</script>

<style>
#components-layout-demo-custom-trigger {
  height: 100%;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;

  margin: 16px;
}

.title {
  color: #fff;
  text-align: center;
  line-height: 32px;
}
</style>