import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    // 当做 data
    state: {
        userRole: null, // 用于存储用户角色
    },
    mutations: {
        setUserRole(state, role) {
            state.userRole = role;
            // 将角色存储到 LocalStorage
            localStorage.setItem('userRole', role);
        },
        initializeUserRole(state) {
            // 从 LocalStorage 中读取角色
            const role = localStorage.getItem('userRole');
            if (role) {
                state.userRole = role;
            }
        },
    },
    actions: {
        updateUserRole({ commit }, role) {
            commit('setUserRole', role);
        },
        initializeUserRole({ commit }) {
            commit('initializeUserRole');
        },
    },
});