<template>
    <div>
        <a-card :bordered="false">
            <h3>虚拟卡列表</h3>
            <a-row :gutter="20">
                <a-col :span="4">
                    <a-cascader allowClear :options="cardNumber_options" placeholder="请输入卡号查找" @change="() => { this.paginationOption.defaultCurrent = 1; getArticleList(); }"
                        v-model="querydata.card_number" />
                </a-col>
                <a-col :span="4">
                    <!-- <a-input-search placeholder="请输入支付类型查找" enter-button v-model="querydata.transaction_type" la
                        @search="getArticleList"></a-input-search> -->
                    <a-cascader allowClear :options="options" placeholder="请输入支付类型查找" @change="() => { this.paginationOption.defaultCurrent = 1; getArticleList(); }"
                        v-model="querydata.transaction_type" />
                </a-col>
                <a-col :span="5">
                    <a-cascader allowClear :options="bridgeOptions" placeholder="请选择是否出现FB账单" @change="() => { this.paginationOption.defaultCurrent = 1; getArticleList(); }"
                        v-model="querydata.is_judge" />
                </a-col>
                <a-col :span="5">
                    <a-range-picker @change="() => { this.paginationOption.defaultCurrent = 1; getArticleList(); }" v-model="time_range" />
                </a-col>
                <a-col :span="2">
                    <a-button type="primary"@change="() => { this.paginationOption.defaultCurrent = 1; getArticleList(); }">查询</a-button>
                </a-col>
                <a-col :span="2">
                    <a-button type="primary" @click="getClear">置空</a-button>
                </a-col>
            </a-row>
            <!-- 触发上传弹窗的按钮 -->
            <a-button type="primary" @click="showDialog" style="margin-top:10px ;">虚拟卡文件导入</a-button>
            <a-modal v-model:visible="dialogVisible" title="批量导入" @ok="handleOk">
                <p>为确保导入数据格式正确，请按照格式规范录入数据</p>
                <a-upload-dragger v-model:fileList="fileList" name="f1" :multiple="true"
                    action="http://111.90.143.10:39010/api/v1/upload1" @change="handleChangeFile">
                    <p class="ant-upload-drag-icon">
                        <a-icon type="cloud-download" />
                    </p>
                    <p class="ant-upload-text">点击或将文件拖拽到这里上传</p>
                    <p class="ant-upload-hint">
                        仅支持*xlsx文件
                    </p>
                </a-upload-dragger>
                <div class="upload-tips">
                    <p>1.仅支持*xls文件</p>
                    <p>2.导入数量不得超过5000条</p>
                    <p>3.文件大小不得超过3M</p>
                </div>
            </a-modal>
            <a-table :rowKey="(record, index) => { return index }" :columns="filteredColumns" :pagination='paginationOption'
                :dataSource="Articlelist" bordered>
                <span slot="customTitle">
                    <div style="display: flex;">时间 <span class="icon-type"><a-icon type="caret-up"
                                @click="getSortArticleList(0)" /><a-icon type="caret-down"
                                @click="getSortArticleList(1)" /></span></div>
                </span>
                <template slot="action" slot-scope="text">
                    <!-- 使用三元运算符来根据布尔值显示不同的文本 -->
                    <span :style="{ color: text.is_judge ? 'green' : 'red' }">
                        {{ text.is_judge ? '是' : '否' }}
                    </span>
                </template>
            </a-table>
        </a-card>

    </div>
</template>

<script>
import { message } from 'ant-design-vue';
const moment = require('moment');
export default {
    data() {
        return {
            labelCol: { span: 5 },
            wrapperCol: { span: 16 },
            paginationOption: {
                pageSizeOptions: ['10', '20', '50'],
                defaultCurrent: 1,
                defaultPageSize: 10,
                showSizeChanger: true,
                showTotal: (total) => `共${total}条`,
                onChange: (current, size) => {
                    this.paginationOption.defaultCurrent = current
                    this.paginationOption.defaultPageSize = size
                    this.getArticleList()

                },
                onShowSizeChange: (current, size) => {
                    this.paginationOption.defaultCurrent = 1
                    this.paginationOption.defaultPageSize = size
                    this.getArticleList()
                },
                total: 0
            },
            Articlelist: [],
            Catelist: [],
            querydata: {
                pagesize: 0,
                pagenum: 0,
                card_number: '',
                transaction_type: '',
                start_time: '',
                end_time: '',
                is_judge: ''
                // title: '',
            },
            //time_range:[],
            time_range: [],
            dialogVisible: false, // 控制弹窗的显示  
            fileList: [], // 已上传的文件列表  
            vccBalance: 0,
            vccVisible: false,
            options: [{
                value: '开卡',
                label: '开卡'
            }, {
                value: '交易授权',
                label: '交易授权'
            }, {
                value: '交易清算',
                label: '交易清算'
            }, {
                value: '卡充值',
                label: '卡充值'
            }, {
                value: '卡充退',
                label: '卡充退'
            }, {
                value: '卡注销',
                label: '卡注销'
            }],
            bridgeOptions: [{
                value: 1,
                label: '是'
            }, {
                value: 0,
                label: '否'
            }],
            cardNumber_options: [],
            visibleFB: true,
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'transaction_id',
                    width: '16%',
                    key: 'transaction_id',
                    align: 'center',
                },
                {
                    title: 'NickName',
                    dataIndex: 'nickname',
                    width: '12%',
                    key: 'nickname',
                    align: 'center',
                },
                {
                    slots: { title: 'customTitle' },
                    dataIndex: 'transaction_time',
                    width: '14%',
                    key: 'transaction_time',
                    align: 'center',
                },
                {
                    title: '卡号',
                    dataIndex: 'card_number',
                    width: '6%',
                    key: 'card_number',
                    align: 'center',

                },

                {
                    title: '类型',
                    dataIndex: 'transaction_type',
                    width: '8%',
                    key: 'transaction_type',
                    align: 'center',
                },
                {
                    title: 'order-amount',
                    dataIndex: 'order_amount',
                    width: '8%',
                    key: 'order_amount',
                    //scopedSlots: { customRender: 'img' },
                    align: 'center',
                },
                {
                    title: 'order-currency',
                    dataIndex: 'order_currency',
                    width: '8%',
                    key: 'order_currency',
                    //scopedSlots: { customRender: 'img' },
                    align: 'center',
                },
                {
                    title: 'result_description',
                    dataIndex: 'result_description',
                    width: '10%',
                    key: 'result_description',
                    //scopedSlots: { customRender: 'img' },
                    align: 'center',
                },
                {
                    title: '是否出现在fb账单上',
                    //dataIndex: 'is_judge',
                    width: '10%',
                    key: 'action',
                    scopedSlots: { customRender: 'action' },
                    // 不需要 scopedSlots，因为我们在模板中直接定义了它  
                    align: 'center',
                },

            ]
        }
    },
    created() {
        this.getArticleList()
        this.getCardNumberOptions()
    },
    computed: {
       filteredColumns() {
      // 根据状态变量过滤列
        return this.columns.filter(column => {
            if (column.key === 'transaction_type') {
            return this.visibleFB;
            }
            return true; // 其他列始终显示
        });
      },
  },
    methods: {
        // 显示上传文件的弹窗  
        showDialog() {
            this.dialogVisible = true;
        },
        handleChangeFile(info) {
            console.log(info, 'info')
            const status = info.file.status;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} 文件上传成功！`);
                //this.Articlelist()
            } else if (status === 'error') {
                const responses = info.file.response.match(/({.*?})/g); // 使用正则表达式提取 JSON 
                // 解析第一个 JSON 对象
                const info1 = JSON.parse(responses[0]); // 解析第一个对象
                console.log(info1, 'info1');
                message.error(`${info1.msg} 文件上传失败！`);
            }
        },
        async showVccBalance(id) {
            const result = await this.$http.post('showVccBalance', { card_number: id });
            console.log(result, result.data.dsg.Number, '=====111')
            if (result.status != 200) return this.$message.error(result.message)
            this.vccBalance = result.data.dsg.Number
            console.log(this.vccBalance)
            this.vccVisible = true
        },
        handleOk(e) {
            console.log(e);
            this.dialogVisible = false;
            this.getArticleList()
        },
        //文章列表
        async getArticleList() {
            console.log(this.time_range, '=====>time')
            this.visibleFB = (this.querydata.is_judge === '' || this.querydata.is_judge === -1) ? true : false;
            this.querydata.pagesize = this.paginationOption.defaultPageSize
            this.querydata.pagenum = this.paginationOption.defaultCurrent
            this.querydata.start_time = (this.time_range[0])?.startOf('day').unix()
            this.querydata.end_time = (this.time_range[1])?.endOf('day').unix()
            const is_judge = this.querydata.is_judge === '' ? -1 : this.querydata.is_judge
            const url = `showvcc_record?pagesize=${this.querydata.pagesize}&pagenum=${this.querydata.pagenum}&card_number=${(this.querydata.card_number || [])}&transaction_type=${(this.querydata.transaction_type || [])}&start_time=${this.querydata.start_time}&end_time=${this.querydata.end_time}&is_judge=${is_judge}`;
            const { data: result } = await this.$http.get(url);
            if (result.code != 200) return this.$message.error(result.message)
            this.Articlelist = result.data
            this.paginationOption.total = result.total
        },
        async getCardNumberOptions() {
            const { data: result } = await this.$http.get('showVccID')
            if (result.code != 200) return this.$message.error(result.message)
            this.cardNumber_options = result.data.map(number => ({
                value: number,
                label: number
            }))
            console.log(this.cardNumber_options, '======>123')
        },
        async getSortArticleList(id) {
            this.getCardNumberOptions()
            this.querydata.pagesize = this.paginationOption.defaultPageSize
            this.querydata.pagenum = this.paginationOption.defaultCurrent
            this.querydata.start_time = (this.time_range[0])?.startOf('day').unix()
            this.querydata.end_time = (this.time_range[1])?.endOf('day').unix()
            const is_judge = this.querydata.is_judge === '' ? -1 : this.querydata.is_judge
            const url = `showvcc_record?pagesize=${this.querydata.pagesize}&pagenum=${this.querydata.pagenum}&card_number=${(this.querydata.card_number || [])}&transaction_type=${(this.querydata.transaction_type || [])}&start_time=${this.querydata.start_time}&end_time=${this.querydata.end_time}&is_judge=${is_judge}&set=${id}`;
            const { data: result } = await this.$http.get(url);
            console.log(result, '====88')
            if (result.code != 200) return this.$message.error(result.message)
            this.Articlelist = result.data
            console.log(this.Articlelist, '=====>xihuan')
            this.paginationOption.total = result.tatal
        },
        getClear() {
            this.querydata = {
                pagesize: 0,
                pagenum: 0,
                card_number: '',
                transaction_type: '',
                is_judge: -1
            }
            this.time_range = []
            this.getArticleList()
        },
        async findArticle() {
            this.querydata.pagesize = this.paginationOption.defaultPageSize
            this.querydata.pagenum = 1
            const { data: result } = await this.$http.post('articles', this.querydata)
            if (result.status != 200) return this.$message.error(result.message)
            this.Articlelist = result.data
            this.paginationOption.total = result.total
        },
        //删除文章
        deleteArticle(id) {
            this.$confirm({
                title: "确定要删除吗？",
                content: "一旦删除，无法恢复",
                onOk: async () => {
                    const { data: result } = await this.$http.delete(`article/${id}`)
                    if (result.status != 200) return this.$message.error(result.message)
                    this.$message.success('删除成功')
                    this.getArticleList()
                },
                onCancel: () => {
                    this.$message.info("已取消删除")
                }
            })
        },
        //获取分类列表
        async getCateList() {
            const { data: result } = await this.$http.get('category')
            if (result.status != 200) return this.$message.error(result.message)
            this.Catelist = result.data
            this.pagination.total = result.total
        },
        //查询分类下的文章
        CateChange(value) {
            this.getCateArticle(value)
        },
        async getCateArticle(id) {
            const { data: result } = await this.$http.get(`article/cate/${id}`, {
                params: {
                    pagesize: this.paginationOption.defaultPageSize,
                    pagenum: this.paginationOption.defaultCurrent
                }
            })
            if (result.status != 200) return this.$message.error(result.message)
            this.Articlelist = result.data
            this.paginationOption.total = result.total
        },
        // 显示上传文件的弹窗  
        showDialog() {
            this.dialogVisible = true;
        }
    },
}
</script>

<style scoped>
.actionSlot {
    display: flex;
    justify-content: center;
}

::v-deep .ant-cascader-menu {
    height: 200px;
}

.icon-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 5px
}

::v-deep .anticon {
    margin-bottom: -5px;
}

::v-deep .anticon:hover {
    cursor: pointer;
    color: #40a9ff
}
</style>
