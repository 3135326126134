<template>
    <div>

        <a-card :bordered="false">
            <h3>FB虚拟卡扣款</h3>
            <a-row :gutter="20">
                <a-col :span="6">
                    <a-input-search placeholder="请输入账户查找" enter-button v-model="querydata.account" label="账户"
                        @search="getAccountAllList"></a-input-search>
                </a-col>
                <a-col :span="6">
                    <a-input-search placeholder="请输入卡号查找" enter-button v-model="querydata.payment_method" la
                        @search="getAccountAllList"></a-input-search>
                </a-col>
                <a-col :span="4">
                    <a-button type="primary" @click="getAccountAllList">查询</a-button>
                </a-col>
                <a-col :span="4">
                    <a-button type="primary" @click="queryTotal">查询卡的总账</a-button>
                    <span v-if="totalAccount !== null"> {{ totalAccount }}</span>
                </a-col>
            </a-row>

            <a-table :rowKey="(record, index) => { return index }" :columns="columns" :pagination='paginationOption'
                :dataSource="Articlelist" bordered>
                <span slot="customTitle"><div style="display: flex;">时间 <span class="icon-type"><a-icon type="caret-up" @click="sortList(1)" /><a-icon type="caret-down" @click="sortList(2)"/></span></div></span>
            </a-table>
        </a-card>

    </div>
</template>

<script>
const columns = [
    {
        title: '卡号',
        dataIndex: 'card_number',
        width: '20%',
        key: 'card_number',
        align: 'center',

    },
    {
        slots: { title: 'customTitle' },
        dataIndex: 'Date',
        width: '20%',
        key: 'Date',
        align: 'center',
    },
    {
        title: '扣费数额',
        dataIndex: 'Amount',
        width: '20%',
        key: 'Amount',
        align: 'center',

    }
]
export default {
    data() {
        return {
            labelCol: { span: 5 },
            wrapperCol: { span: 16 },
            Articlelist: [],
            Catelist: [],
            columns,
            querydata: {
                payment_method: '',
                account: '',
            },
            dialogVisible: false, // 控制弹窗的显示  
            fileList: [], // 已上传的文件列表 
            vccBalence: 0,
            vccVisible: false,
            updateFBList: {
                transaction_id: "",
                is_ticked: false,
                note: ""
            },
            resultArr: [],
            isCardAndAccount: false,
            totalAccount:null
        }
    },
    created() {
        this.getAccountAllList()
    },
    methods: {
        //文章列表
        async getAccountAllList() {
            if (this.querydata.account !== '' && this.querydata.payment_method === '') {
                const { data: result } = await this.$http.post('showfb_vccdata', { account: this.querydata.account });
                if (result.code != 200) return this.$message.error(result.message)
                for (const [card_number, entries] of Object.entries(result.data)) {
                    for (const entry of entries) {
                        this.resultArr.push({ card_number, ...entry });
                    }
                }
                this.Articlelist = this.resultArr
            } else if (this.querydata.account !== '' && this.querydata.payment_method !== '') {
                this.isCardAndAccount = true
                const { data: result } = await this.$http.post('showFBByaccountList', { account: this.querydata.account, payment_method: this.querydata.payment_method });
                if (result.code != 200) return this.$message.error(result.message)
                // 更新数组中的每一项
                this.Articlelist = result.data.map(item => {
                    return {
                        card_number: this.querydata.payment_method,
                        Date: item.date, // 重命名 Date 为 date
                        Amount: item.amount, // 重命名 Amount 为 amount
                    };
                });
            }
        },
        async queryTotal() {
            if(this.querydata.payment_method !== '') {
                const { data: result } = await this.$http.post('showFBDataByaccount',{ account:this.querydata.account, payment_method: this.querydata.payment_method });
                if (result.status != 200) return this.$message.error(result.message)
                this.totalAccount = result.data
            }else {
                message.error('未填写卡号')
            }
            
        },
        async sortList(type) {
          
        }
    }
}
</script>

<style scoped>
.actionSlot {
    display: flex;
    justify-content: center;
}
.icon-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left:5px
}
::v-deep .anticon {
    margin-bottom: -5px;
}
::v-deep .anticon:hover {
    cursor: pointer;
}
</style>
