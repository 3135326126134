<template>
    <div>

        <a-card :bordered="false">
            <h3>FB账号总余额和总消耗计算</h3>
            <a-row :gutter="20" style="margin-top: 10px;">
                <a-col :span="4">
                    <a-cascader allowClear :options="accountOptions" placeholder="请输入FB账号查找"
                        @change="() => { getVirtualCard(); }"
                        v-model="queryParams.account" />
                </a-col>
                <a-col :span="5">
                    <a-range-picker @change="() => { getVirtualCard(); }"
                        v-model="time_range" />
                </a-col>
                <a-col :span="2">
                    <a-button type="primary"
                        @click="() => { getVirtualCard(); }">查询</a-button>
                </a-col>
                <a-col :span="2">
                    <!-- this.paginationOption.defaultCurrent = 1; -->
                    <a-button type="primary" @click="getClear">置空</a-button>
                </a-col>
            </a-row>
            <a-table :rowKey="(record, index) => { return index }" :columns="columns" :dataSource="accountBalances"
                bordered>
            </a-table>
        </a-card>

    </div>
</template>

<script>
//import { CloudUploadOutlined,InboxOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
const columns = [
    {
        title: '账号',
        dataIndex: 'account',
        width: '10%',
        key: 'account',
        align: 'center',
    },
    {
        title: '总余额',
        dataIndex: 'totalBalance',
        width: '15%',
        key: 'totalBalance',
        align: 'center',
    },
    {
        title: '总消耗',
        dataIndex: 'totalDeplete',
        width: '15%',
        key: 'totalDeplete',
        align: 'center',

    }
]
export default {
    data() {
        return {
            labelCol: { span: 5 },
            wrapperCol: { span: 16 },
            columns,
            querydata: {
                year: '',
                month: ''
            },
            virtualCardNumber: [],
            virtualCardList: [],
            month_consumption: '',
            queryParams: {
                account: '',
                start_time: '',
                end_time: ''
                // title: '',
            },
            time_range: [],
            cardNumber_options: [],
            accountOptions: [],
            totalBalance: '',
            totalDeplete: '',
            accountBalances: []
        }
    },
    created() {
        //  this.getVirtualCard()
        this.getAccountOptions()
    },
    methods: {
        async getVirtualCard() {
            this.querydata.start_time = (this.time_range[0])?.startOf('day').unix()
            this.querydata.end_time = (this.time_range[1])?.endOf('day').unix()
            // 获取用户输入的账号
            const filteredAccounts = this.getFilteredAccounts();
            console.log(filteredAccounts,'====>filter')
            this.accountBalances = [];
            filteredAccounts.map(async (account) => {
                const { data: result } = await this.$http.get(`showTotalBalanceAndDeplete?account=${(account|| [])}&start_time=${this.querydata.start_time}&end_time=${this.querydata.end_time}`);
                if (result.code != 200) return this.$message.error(result.message)
                // 检查账户是否已经存在于 accountBalances 中
                const existingBalance = this.accountBalances.find(item => item.account === account);
                console.log(existingBalance,'======>existing')
                if (!existingBalance) {
                    this.accountBalances.push({
                        account: account,
                        totalBalance: result.total_balance,
                        totalDeplete: result.total_deplete
                    });
                } else {
                    // 如果需要，更新现有的账户余额
                    existingBalance.totalBalance = result.total_balance;
                    existingBalance.totalDeplete = result.total_deplete;
                }
            })
            console.log(this.accountBalances,'======>accountBalances')
        },
        // 获取筛选后的账号
        getFilteredAccounts() {
            // 假设用户输入的账号存储在 this.inputAccount 中
            const userInputAccount = this.queryParams.account

            // 如果用户输入了账号，返回该账号；否则返回 accountOptions 中的所有账号
            if (userInputAccount) {
                return [userInputAccount];
            } else {
                return this.accountOptions.map(account => account.value);
            }
        },
        async getAccountOptions() {
            const { data: result } = await this.$http.get('showFBID?account=')
            if (result.code != 200) return this.$message.error(result.message)
            this.accountOptions = result.data1.map(number => ({
                value: number,
                label: number
            }))
            console.log('总余额', this.accountOptions)
            this.getVirtualCard()
        },
        getClear() {
            this.queryParams = {
                start_time: "",
                end_time: ""

            }
            this.time_range = []
            this.getVirtualCard()
        },
    }
}
</script>

<style scoped>
.actionSlot {
    display: flex;
    justify-content: center;
}
</style>
